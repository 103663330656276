import React from 'react'
import styled from '@emotion/styled'
import { Body1, Heading2, Subtitle } from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'
import { MediaQuery } from '@/src/utils/enums'

interface FeatureIntroductionLayoutProps {
  headingTexts: string[]
  subtitleText?: string
  bodyTexts: string[]
  imageComponent: React.ReactNode
}

export default function FeatureIntroductionLayout({
  headingTexts,
  subtitleText,
  bodyTexts,
  imageComponent,
}: FeatureIntroductionLayoutProps) {
  return (
    <FeatureIntroductionLayoutWrapper>
      <FeatureIntroductionTextBox>
        <HeadingTextBox>
          {headingTexts.map((text) => (
            <Heading2 style={{ color: colors.gray11() }} key={text}>
              {text}
            </Heading2>
          ))}
        </HeadingTextBox>
        {subtitleText && (
          <SubtitleLayout>
            <Subtitle style={{ color: colors.gray11() }}>
              {subtitleText}
            </Subtitle>
          </SubtitleLayout>
        )}
        <BodyTextLayout>
          {bodyTexts.map((text) => {
            return (
              <Body1 style={{ color: colors.gray33() }} key={text}>
                {text}
              </Body1>
            )
          })}
        </BodyTextLayout>
      </FeatureIntroductionTextBox>
      <FeatureImageBox>{imageComponent}</FeatureImageBox>
    </FeatureIntroductionLayoutWrapper>
  )
}

const FeatureIntroductionLayoutWrapper = styled.div`
  display: flex;

  ${MediaQuery.PC} {
    align-items: center;
    gap: 80px;
  }
  ${MediaQuery.TABLET} {
    flex-direction: column;
    gap: 40px;
  }
  ${MediaQuery.MOBILE} {
    flex-direction: column;
    gap: 20px;
  }
`

const FeatureIntroductionTextBox = styled.div`
  flex-shrink: 0;

  ${MediaQuery.PC} {
    width: 42%;
  }
  ${MediaQuery.TABLET} {
    width: 100%;
  }
  ${MediaQuery.MOBILE} {
    width: 100%;
  }
`

const HeadingTextBox = styled.div`
  display: flex;
  flex-direction: column;
`

const SubtitleLayout = styled.div`
  ${MediaQuery.PC} {
    margin-top: 24px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 16px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 12px;
  }
`
const BodyTextLayout = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.PC} {
    margin-top: 16px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 12px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 8px;
  }
`

const FeatureImageBox = styled.div`
  ${MediaQuery.PC} {
    width: 71%;
    margin-right: -210px;
  }
  ${MediaQuery.TABLET} {
    width: 100%;
  }
  ${MediaQuery.MOBILE} {
    width: 100%;
  }
`
