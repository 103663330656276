import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { TypedIcon } from 'typed-design-system'

import { Body2, Heading2, Heading3 } from '@/src/styles/landingPageCommonStyles'
import colors from '@/src/styles/colors'
import shadows from '@/src/styles/shadows'
import { ICP } from '@/src/locale/translate.website'
import { UserLanguageContext } from '@/src/locale/LocaleContext'
import { MediaQuery } from '@/src/utils/enums'

interface ICPNavigatorProps {
  headingText: 'See how others Typed' | 'See how you can Typed'
  ICPsToUse: (keyof typeof ICP)[]
}

export default function ICPNavigator({
  headingText,
  ICPsToUse,
}: ICPNavigatorProps) {
  const {
    textWithLocale: {
      website: { icps },
    },
  } = useContext(UserLanguageContext)
  return (
    <ICPNavigatorWrapper>
      <ICPNavigatorTitleBox>
        <Heading2 style={{ color: colors.gray11() }}>
          {icps[headingText]}
        </Heading2>
      </ICPNavigatorTitleBox>
      <ICPCardBox>
        {ICPsToUse.map((ICPKey) => {
          const ICP = icps.items[ICPKey]
          return (
            <ICPCard href={ICP.path} key={ICP.title}>
              <ICPCardImageBox>
                <ICPCardImage src={ICP.imgSrc} />
              </ICPCardImageBox>
              <ICPCardTextBox>
                <ICPCardTitleBox>
                  <Heading3 style={{ color: colors.gray11() }}>
                    {ICP.title}
                  </Heading3>
                </ICPCardTitleBox>
                <ICPCardBody2Layout>
                  <Body2 style={{ color: colors.gray33() }}>
                    {ICP.bodyText}
                  </Body2>
                </ICPCardBody2Layout>
                <LearnButtonLayout>
                  <LearnButton>
                    {icps.learnText}
                    <TypedIcon icon='arrow_right_small' />
                  </LearnButton>
                </LearnButtonLayout>
              </ICPCardTextBox>
            </ICPCard>
          )
        })}
      </ICPCardBox>
    </ICPNavigatorWrapper>
  )
}

const ICPNavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${MediaQuery.PC} {
    gap: 80px;
  }
  ${MediaQuery.TABLET} {
    gap: 40px;
  }
  ${MediaQuery.MOBILE} {
    gap: 40px;
  }
`

const ICPNavigatorTitleBox = styled.div`
  width: 100%;
  text-align: center;
`

const ICPCardBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${MediaQuery.PC} {
    gap: 20px;
  }
  ${MediaQuery.TABLET} {
    gap: 16px;
  }
  ${MediaQuery.MOBILE} {
    gap: 16px;
  }
`

const ICPCard = styled.a`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: 1px solid ${colors.darker8()};
  border-radius: 10px;
  background-color: ${colors.gray100()};
  box-shadow: ${shadows.light10};
  transition: transform 0.2s ease-in-out;

  :hover {
    transform: scale(0.97);
  }
  ${MediaQuery.PC} {
    flex: 1 1 calc(25% - 20px);
  }
  ${MediaQuery.TABLET} {
    flex: 1 1 calc(50% - 8px);
  }
  ${MediaQuery.MOBILE} {
    flex: 1 1 calc(50% - 8px);
  }
`

const ICPCardImageBox = styled.div`
  overflow: hidden;
  width: 100%;
  max-height: 180px;
`

const ICPCardImage = styled.img`
  position: relative;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);

  ${MediaQuery.MOBILE} {
    transform: translate(-50%, -50%) scale(1.1);
  }
`

const ICPCardTextBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 0;

  ${MediaQuery.PC} {
    padding: 24px 16px;
  }
  ${MediaQuery.TABLET} {
    padding: 24px 16px;
  }
  ${MediaQuery.MOBILE} {
    padding: 16px 16px;
  }
`

const ICPCardTitleBox = styled.div`
  color: ${colors.gray11()};
  text-align: center;
`

const ICPCardBody2Layout = styled.div`
  color: ${colors.gray33()};
  text-align: center;

  ${MediaQuery.PC} {
    margin-top: 6px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 6px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 3px;
  }
`

const LearnButtonLayout = styled.div`
  ${MediaQuery.PC} {
    margin-top: 17px;
  }
  ${MediaQuery.TABLET} {
    margin-top: 17px;
  }
  ${MediaQuery.MOBILE} {
    margin-top: 26px;
  }
`

const LearnButton = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  color: ${colors.blue50()};
  font-size: 18px;
  line-height: 1.5;

  svg {
    width: 17px !important;
    fill: ${colors.blue50()} !important;
  }
`
